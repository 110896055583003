//import $ from 'jquery';
import 'what-input';
import {accordion} from "./components/accordion";
import {footer} from './components/footer';
import {slider} from './components/slider';
import {search} from "./components/search";
import {map} from './components/map';
import {backToTop} from "./components/backToTop";
import {cookieConsent} from "./components/cookieConsent";
import './lib/foundation-explicit-pieces';

import {dom, library} from '@fortawesome/fontawesome-svg-core';
import { faPhone, faFax, faChevronCircleUp, faBars, faSearch, faUser, faSignOutAlt, faChevronUp, faEnvelope, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import '../scss/app.scss';
import {timeline} from "./components/timeline";

const sliderClass = '.bxslider';

window.jQuery = $;
window.jQuery = jQuery;


// cookie consent must fire before
cookieConsent.initCookieConsent();

$(function () {

    library.add(faPhone, faFax, faChevronCircleUp, faBars, faSearch, faUser, faSignOutAlt, faChevronUp, faLinkedin, faEnvelope, faExclamationTriangle);
    dom.i2svg();

    $(document).foundation();
    slider.initBXSlider(sliderClass);
    footer.initFooterPosition();
    search.initSearchButton();
    //timeline.initTimeline();
    //map.initLeafletMaps();
    backToTop.initBackTopButton();
    accordion.initAccordion();
    accordion.openAccordionSubmenu();
});

$(document).ready(function() {
    // moved accordion to above, may not be necessary
});


