import 'BXSlider';

const defaultSettings = {
    auto: 1,
    pause: 4000,
    speed: 1200,
    mode: 'horizontal',
    slideMargin: 0,
    slideWidth: 300,
    caption: 0,
    adaptiveHeight: false,
    pager: true,
    touchEnabled: false,
    tickerHover: false,
    ticker: false,
    minSlides: 1,
    maxSlides: 1,
    moveSlides: 0,
    shrinkItems: false,
    keyboardEnabled: false,
    randomStart: false,
    controls: true,
    infiniteLoop: true,
    autoHover: false
};

class Slider {

    initBXSlider(sliderClass = '.bxslider') {

        let bxSlider = $(sliderClass) ;

        bxSlider.each(function(index,bxSliderElement){
            let sliderBx = $(bxSliderElement);
            let singleSlide = (sliderBx.children().length < 2);
            let modifiedSettings = {...defaultSettings};

            $.each(sliderBx.data(), function (key, value) {
                //console.log(key + ' ' + value);
                let adjustedDataAttribute = key.replace("slider","");
                adjustedDataAttribute = adjustedDataAttribute[0].toLowerCase() + adjustedDataAttribute.substring(1);
                //console.log(adjustedDataAttribute);
                modifiedSettings[adjustedDataAttribute] = value;
            })

            //console.log('defaultSettings');
            //console.log(defaultSettings);
            //console.log('modifiedSettings');
            //console.log(modifiedSettings);
            //console.log(modifiedSettings.infiniteLoop);

            if(singleSlide){modifiedSettings.infiniteLoop = false}

            if (sliderBx.length) {
                sliderBx.bxSlider({
                    auto: modifiedSettings.auto,
                    pause: modifiedSettings.pause,
                    speed: modifiedSettings.speed,
                    mode: modifiedSettings.mode,
                    margin: modifiedSettings.margin,
                    caption: modifiedSettings.caption,
                    infiniteLoop: modifiedSettings.infiniteLoop,
                    controls: modifiedSettings.controls,
                    //slideWidth: modifiedSettings.slideWidth,
                    //slideMargin: modifiedSettings.slideMargin,
                    adaptiveHeight: modifiedSettings.adaptiveHeight,
                    pager: modifiedSettings.pager,
                    touchEnabled: modifiedSettings.touchEnabled,
                    tickerHover: modifiedSettings.tickerHover,
                    ticker: modifiedSettings.ticker,
                    minSlides: modifiedSettings.minSlides,
                    maxSlides: modifiedSettings.maxSlides,
                    moveSlides: modifiedSettings.moveSlides,
                    shrinkItems: modifiedSettings.shrinkItems,
                    keyboardEnabled: modifiedSettings.keyboardEnabled,
                    randomStart: modifiedSettings.randomStart,
                    autoHover: modifiedSettings.autoHover,
                    onSliderLoad: function(){
                        sliderBx.css("visibility", "visible");
                    }
                });
            }
        });
    }
}

export let slider = new Slider();
