class Timeline {
    initTimeline() {
        const el = document.querySelector('.timeline');
        if(el == null){
            return;
        }
        el.style.display = 'block';

        // define variables
        let items = document.querySelectorAll(".timeline .timeline__section");

        // check if an element is in viewport
        function isElementInViewport(el) {
            let rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function callbackFunc() {
            for (let i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                    items[i].classList.add("timeline__section--in-view");
                }
            }
        }

        // listen for events
        window.addEventListener("load", callbackFunc);
        window.addEventListener("resize", callbackFunc);
        window.addEventListener("scroll", callbackFunc);
    }
}

export let timeline = new Timeline();
