class Footer {
    initFooterPosition() {

        const footerContainerSelector = 'footer';
        const headerContainerSelector = 'header';
        const contentContainerSelector = 'main';

        let footerHeight = $(footerContainerSelector).outerHeight(true);
        let topBarHeight = $(headerContainerSelector).outerHeight(true);

        $(contentContainerSelector).css('min-height', 'calc(100vh - ' + ((footerHeight) + (topBarHeight)) + 'px');
        $(footerContainerSelector).css('opacity', '1');
    }
}

export let footer = new Footer();
