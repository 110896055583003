import L from  'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

class Map {
    initLeafletMaps() {

        //fix marker issue
        let DefaultIcon = L.icon({
            iconUrl: icon,
            shadowUrl: iconShadow,
            iconSize: [24,41],
            iconAnchor: [12,41]
        });
        L.Marker.prototype.options.icon = DefaultIcon;

        let mLat = 0.0;
        let mLng = 0.0;
        let mZoom = 10;
        let $maps =  $('.leafletMap');

        if($maps === false){
            return;
        }

        $maps.each(function (){
            let jMap = $(this);

            mLat = parseFloat(jMap.data("lat"));
            mLng = parseFloat(jMap.data("lng"));
            mZoom = parseFloat(jMap.data("zoom"));

            let map = L.map(this, {
                center: [mLat, mLng],
                zoom: mZoom
            });

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            L.marker([mLat, mLng]).addTo(map);
        })
    }
}

export let map = new Map();
