class CookieConsent {

    /**
     * Initiate the consent by adding an EventListener on 'cookieConsent' which will
     * be fired on two occasions:
     * 1. clicking on save or save all in the modal dialog
     * 2. as long as the consent cookie ('cookie_consent' or named different via typoscript) exists
     *    as soon as the cookie_consent.js of the extension is loaded (default in footer).
     * So be aware this function is loaded *before* the event is fired, otherwise you won't get the proper
     * information on customer decisions as long as you are not implementing your own logic. But we're lazy, are we?
     */
    initCookieConsent() {
        let that = this;
        window.matomoLoaded = false;
        let matomoSrc = that.getMatomoSrc();
        window.addEventListener('cookieConsent', function (event) {
            var _paq = window._paq = window._paq || [];

            if (event.detail.hasOption('matomo')) {
                if (false === window.matomoLoaded) {
                    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    _paq.push(['requireCookieConsent']);
                    (function() {
                        var u= matomoSrc;
                        _paq.push(['setTrackerUrl', u+'matomo.php']);
                        _paq.push(['setSiteId', '1']);
                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                        g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                    })();
                    console.log('matomo enabled');
                    _paq.push(['setCookieConsentGiven']);
                }
            } else {
                console.log('matomo disabled');
                _paq.push(['forgetCookieConsentGiven']);
                _paq.push(["disableCookies"]);
                _paq.push(["deleteCookies"]);
                that.eraseMatomoCookies();
            }
        });
    }

    /**
     * Hence Matomo is not 'deleting' its own cookies (even if not used) this might cause confusion on the side
     * of data protection officers. So we added a small function to erase all Matomo cookies.
     */
    eraseMatomoCookies() {
        let cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (name.includes('_pk_')) {
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        }
    }

    /**
     * In order to make this script more customizable we introduced live and general dev environment.
     * Rule: if not live environment it will be handled with the dev instance of matomo. adapt to your own needs.
     * @returns {string}
     */
    getMatomoSrc() {
        let myLocation = window.location;
        let matomoSrc = '//matomo.dev.t39.xion-medical.glutrot.de/';
        // console.log(myLocation.host);
        // if not dev than only use live!
        if (myLocation.host === 'www.xion-medical.com') {
            matomoSrc = '//matomo.xion-medical.com/';
        }
        return matomoSrc
    }
}

export let cookieConsent = new CookieConsent();
