class Accordion {
    initAccordion() {
        if (window.location.hash) {
            let hash = window.location.hash.substring(1);
            let form = document.getElementById(hash);
            let accordion = form.closest('.c-accordion');
            let accordionId = accordion.getAttribute('id');
            if (accordionId) {
                document.getElementById(accordionId).scrollIntoView();
                $('#' + accordionId).foundation('down', $('.c-accordion__content'));
            }

        }
    }

    openAccordionSubmenu() {
        const urlParams = new URLSearchParams(window.location.search);
        const submenuIdToOpen = urlParams.get('subm');
        if (submenuIdToOpen) {
            let submenuToOpen = '#n-submenu-' + submenuIdToOpen;
            jQuery('#n-submenu').foundation('down', jQuery(submenuToOpen));
        }
    }
}

export let accordion = new Accordion();
