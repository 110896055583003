class Search {
    initSearchButton() {
        const icon = document.querySelector('.search-bar__icon');
        const input = document.querySelector('.search-bar__input');
        input.style.display = 'block';
        icon.onclick = () => {
            input.classList.toggle('search-bar__input--visible');
        }
    }
}

export let search = new Search();
